#hero-section {
  height: 500px;
  /* background-size: 50% 50%, cover; */
  /* background-position: right; */
}
/* #hero-section {
  background-size: 50% 100%, cover;
  background-position: right;
} */

.parallelogram-container {
  position: relative;
}

.parallelogram {
  position: absolute;

  background-repeat: no-repeat;

  /* clip-path: polygon(100% 100%, 100% -90%, -3% 100%); */
  width: 100%;
  height: 100%;
  z-index: 6;
  /* background-position: 14px -16px !important; */
}

.small-parallelogram {
  position: absolute;
  z-index: 5;
  /* background: white; */
  width: 100%;
  height: 100%;
  top: 0;
  left: -3px;
  /* clip-path: polygon(100% 100%, 100% -90%, -3% 100%); */
}

.background-container {
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh; /* Adjust as needed */
}

.overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Overlay should be above the background image */
}

.hero-content {
  position: relative;
  z-index: 2; /* Content should be above the overlay */
}

.parallelogram-container {
  position: relative;
  z-index: 2; /* Ensure it is above the overlay */
}
