.login-email {
  width: 100% !important;
  margin-bottom: 32px;
}

.remember-fw-wrapper {
  margin-top: 12px;
  margin-bottom: 34px;
}

.forgot-password {
  color: #ff5f00;
  cursor: pointer;
}

.not-registered {
  margin-top: 8px;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: #d3d3d3;
  margin: 0 10px; /* space between the line and the text */
}
