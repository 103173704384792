.container {
  padding: 20px;
}

.box {
  width: 50%;
  margin-left: 22px;
}

.schedule-detail-card {
  border-radius: 6px;
  width: 390px;
  height: 68px;
  padding: 12px 14px;
}

.pay-detail-card {
  border-radius: 6px;
  width: 285px;
  height: 68px;
  margin-left: 30px;
  margin-bottom: 20px;
  padding: 12px 14px;
}

.reason-card {
  background: #f5f5f5;
  width: 100%;
  resize: none;
  height: 268px;
  border-radius: 6px;
  padding: 16px;
}

.request-button {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  min-width: 150px !important;
  max-width: 220px !important;
  font-size: 0.876rem !important;
  font-weight: 300 !important;
}
