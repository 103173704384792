.td > div {
  overflow: visible !important;
}

.table::-webkit-scrollbar {
  height: 0px; /* Adjust scrollbar height */
  width: 5px;
}

.table::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
}

.table::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136, 0.8); /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded edges */
}

.table::-webkit-scrollbar-thumb:hover {
  background: rgb(85, 85, 85, 0.9); /* Darker thumb on hover */
}
