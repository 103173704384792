.quill {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
/* span {
  color: #686868 !important;
} */
.ql-editor {
  font-size: 13px;
  font-weight: 300;
  margin: 0;
  color: #686868;
}

.ql-toolbar {
  background-color: #f4f4f4;
  border-radius: 10px 10px 0 0;
}

.ql-container {
  border-radius: 0 0 10px 10px;
}

.ql-toolbar.ql-snow {
  border: none;
}
.ql-container.ql-snow {
  border: none;
}

.ql-editor strong {
  font-weight: 600; /* Increase the weight */
  font-size: 13px;
  color: #686868 !important;
}

.ql-editor b {
  font-weight: 600; /* Ensure <b> tags also get the same effect */
  font-size: 13px;
  color: #686868 !important;
}
