.border-bottom {
  border-bottom: 1px solid #dfdfdf;
}

.border-top {
  border-top: 1px solid #dfdfdf;
}
/* 
.MuiStepLabel-label {
  margin: 10px !important;
}

.MuiStepConnector-line {
  border-top-width: 4px !important;
}
.MuiStepConnector-root {
  top: 17px !important;
}

.MuiSvgIcon-root {
  font-size: 40px !important;
}
 */
