.MuiFormControl-root {
  width: 100%;
}

.MuiTextField-root {
}

.MuiInputBase-input {
  padding: 4px 14px !important;
}

.MuiInputBase-root {
  height: 100%;
  font-size: 13px !important;
  font-family: "Poppins" !important;
  font-weight: 300 !important;
  color: #686868 !important;
}

.MuiIconButton-root {
  background-color: #f8f9fc !important;
  border-radius: 0 !important;
  padding: 8px 20px !important;
  border-left: 1px solid #e5e7eb !important;
}

.MuiStack-root {
  padding-top: 0 !important;
}
