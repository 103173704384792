a:hover {
  text-decoration: none;
}

.text-blue-500 {
  color: #3b82f6; /* Example color for active state */
}

.text-gray-500 {
  color: #6b7280; /* Example color for inactive state */
}
