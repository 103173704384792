.container {
  padding: 20px;
}

.box {
  width: 50%;
  margin-left: 22px;
}

.schedule-detail-card {
  border-radius: 6px;
  width: 100% !important;
  height: 68px;
  padding: 12px 14px;
}

.pay-detail-card {
  border-radius: 6px;
  width: 100% !important;
  height: 68px;
  margin-left: 30px;
  margin-bottom: 20px;
  padding: 12px 14px;
}

.reason-card {
  background: #f5f5f5;
  width: 100%;
  resize: none;
  height: 114px !important;
  border-radius: 6px;
  padding: 16px;
}

.button {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  width: 150px !important;
  font-size: 0.876rem !important;
  font-weight: 300 !important;
}

.add-expense-btn {
  background-color: #f6f6f6 !important;
  color: #0f40ae !important;
  font-size: 0.875rem !important;
  border: 1px solid #0f40ae !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  width: 150px !important;
  font-weight: 300 !important;
  margin-top: 40px !important;
}

.add-expense-btn:hover {
  background-color: #0f40ae !important;
  color: white !important;
}
