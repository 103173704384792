.card {
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  background-color: #ffffff;
  min-height: 214px;
  height: 86%;
}

.image-container {
  position: absolute;
  width: 78%;
  height: 58%;
  top: -48%;
  left: 11%;
}
.review-card {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 25.536px 0px #00000014;
  padding: 22px;
}

.review-card-divder {
  border-top: 1px solid #b6b6b6;
}
