.Mui-expanded {
  margin: 0px !important;

  .MuiAccordionSummary-root {
    background-color: #0f40ae;
    border: 50px !important;

    h6 {
      color: #fff !important;
    }
  }
}

.MuiPaper-root {
  margin-bottom: 16px !important;
}

.MuiAccordion-root::before {
  content: none !important;
}
